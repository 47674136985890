/** Constante */
import { CONST } from "../config/constant";
/** Models */
import { MessageContact } from "../models/messgeContact.model";
import { Activity } from "../models/Activity.model";
import { User } from "../models/user.model";
/** Lib */
import moment from "moment";
/** Store */
import { store } from "../store/createStore";
import { build } from "../helpers/url.helper";
import { throwErrorResponse } from "../helpers/http.helper";

/**
 * @param fetchCredentials typeof fetch
 * Get domains
 */
export const getDomains = async (fetchCredentials: typeof fetch) => {
  /** URL Domains */
  const URL_DOMAINS = `${process.env.REACT_APP_API_URL_EL}${CONST.API.DOMAINS}`;

  let result = null;
  /** Result */
  try {
    result = await fetchCredentials(URL_DOMAINS);

    return await result.json();
  } catch (err) {
    result = err.response;
    return result;
  }
};

/**
 * Get category list
 * @param fetchCredentials typeof fetch
 * @param categoryId
 */
export const getCategoryList = async (
  fetchCredentials: typeof fetch,
  categoryId: number
) => {
  /** URL category */
  const URL_CATEGORY = `${process.env.REACT_APP_API_URL_EL}${CONST.API.CATEGORIES}`;

  let result = null;
  /** Result */
  try {
    result = await fetchCredentials(
      URL_CATEGORY.replace(/:categoryId/, categoryId.toString())
    );
    throwErrorResponse(result);
    return await result.json();
  } catch (err) {
    throw err;
  }
};

/**
 * ParamsActivityList: use for getActivityList method
 */
export interface ParamsActivityList {
  orderByPosition: boolean;
  from?: string;
}

/**
 * Get activity list
 * @param fetchCredentials typeof fetch
 * @param categoryId
 * @param orderByPosition
 * @param from
 */
export const getActivityList = async (
  fetchCredentials: typeof fetch,
  categoryId: number,
  orderByPosition: boolean,
  from?: string
) => {
  /** URL Domains */
  const URL_ACTIVITIES = `${process.env.REACT_APP_API_URL_EL}${CONST.API.ACTIVITIES}`;

  const orderByPositionDefault = false;
  /** Params  */
  const params: ParamsActivityList = {
    orderByPosition: orderByPosition ? orderByPosition : orderByPositionDefault
  };

  if (from) {
    params.from = from;
  }

  let result = null;
  /** Result */
  try {
    result = await fetchCredentials(
      build(
        URL_ACTIVITIES.replace(/:categoryId/, categoryId.toString()),
        params
      )
    );
    throwErrorResponse(result);
    return await result.json();
  } catch (err) {
    throw err;
  }
};

/**
 * Get folder
 * @param fetchCredentials typeof fetch
 * @param categoryId number
 */
export const getFolder = async (
  fetchCredentials: typeof fetch,
  categoryId?: number
) => {
  /** URL Folder */
  let URL_FOLDER: string;

  /** Params  */
  let params = {};

  if (categoryId) {
    URL_FOLDER = `${process.env.REACT_APP_API_URL_EL}${CONST.API.FOLDER.BASE}${CONST.API.FOLDER.ID}`;
    URL_FOLDER = URL_FOLDER.replace(/:categoryId/, categoryId.toString());
  } else {
    URL_FOLDER = `${process.env.REACT_APP_API_URL_EL}${CONST.API.FOLDER.BASE}`;
    /** Params  */
    params = {
      limit: "1"
    };
  }

  let result = null;
  /** Result */
  try {
    result = await fetchCredentials(build(URL_FOLDER, params));
    throwErrorResponse(result);

    return await result.json();
  } catch (err) {
    if (err?.status === 204) return undefined;
    throw err;
  }
};
/**
 * Get cover ( use in a-la-une page )
 * @param fetchCredentials typeof fetch
 */
export const getCover = async (fetchCredentials: typeof fetch) => {
  /** URL cover */
  const URL_COVER = `${process.env.REACT_APP_API_URL_EL}${CONST.API.COVER}`;

  let result = null;
  /** Result */
  try {
    result = await fetchCredentials(URL_COVER);
    throwErrorResponse(result);
    return await result.json();
  } catch (err) {
    throw err;
  }
};
/**
 * Get activity
 * @param fetchCredentials typeof fetch
 * @param activityId
 * @param isLesson
 */
export const getActivity = async (
  fetchCredentials: typeof fetch,
  activityId: number,
  isLesson: boolean
) => {
  /** URL Activity */
  let URL_ACTIVITY: string;

  if (isLesson) {
    URL_ACTIVITY = `${process.env.REACT_APP_API_URL_EL}${CONST.API.LESSONS}`;
  } else {
    URL_ACTIVITY = `${process.env.REACT_APP_API_URL_EL}${CONST.API.QUIZ}`;
  }
  URL_ACTIVITY = URL_ACTIVITY.replace(/:activityId/, activityId.toString());

  let result = null;
  /** Result */
  try {
    result = await fetchCredentials(URL_ACTIVITY);

    throwErrorResponse(result);
    return await result.json();
  } catch (err) {
    throw err;
  }
};

/**
 * Get breadCrumb
 * @param fetchCredentials typeof fetch
 * @param CategoryId
 * @param subjectId
 */
export const getBreadCrumb = async (
  fetchCredentials: typeof fetch,
  CategoryId?: string,
  subjectId?: string
) => {
  /** Params  */
  let params = {};

  /** URL BreadCrumb */
  const URL_BREADCRUMB = `${process.env.REACT_APP_API_URL_EL}${CONST.API.BREADCRUMB}`;

  if (CategoryId) {
    params = {
      domain: CategoryId
    };
  }

  if (subjectId && parseInt(subjectId, 10)) {
    params = {
      domain: CategoryId,
      subject: subjectId
    };
  }

  let result = null;
  /** Result */
  try {
    result = await fetchCredentials(build(URL_BREADCRUMB, params));

    throwErrorResponse(result);
    return await result.json();
  } catch (err) {
    throw err;
  }
};

/**
 * Post message contact
 * @param fetchCredentials typeof fetch
 * @param message : MessageContact
 */
export const postMessageContact = async (
  fetchCredentials: typeof fetch,
  message: MessageContact
) => {
  /** URL Contact */
  const URL_CONTACT: string = `${process.env.REACT_APP_API_URL_EL}${CONST.API.CONTACT}`;

  let result = null;
  /** Result */
  try {
    result = await fetchCredentials(URL_CONTACT, {
      method: "post",
      body: JSON.stringify(message)
    });
    throwErrorResponse(result);
    return await result.json();
  } catch (err) {
    throw err;
  }
};
/**
 * getSubjectsContact
 * @param fetchCredentials typeof fetch
 */
export const getSubjectsContact = async (fetchCredentials: typeof fetch) => {
  /** URL subject */
  const URL_SUBJECT: string = `${process.env.REACT_APP_API_URL_EL}${CONST.API.SUBJECT}`;

  let result = null;
  /** Result */
  try {
    result = await fetchCredentials(URL_SUBJECT);
    throwErrorResponse(result);
    return await result.json();
  } catch (err) {
    throw err;
  }
};
/**
 * getUsers
 * @param fetchCredentials typeof fetch
 */
export const getUsers = async (fetchCredentials: typeof fetch) => {
  /** URL users */
  const URL_USERS: string = `${process.env.REACT_APP_API_URL_EL}${CONST.API.USERS}`;

  let result = null;
  /** Result */
  try {
    result = await fetchCredentials(URL_USERS);
    throwErrorResponse(result);
    return await result.json();
  } catch (err) {
    throw err;
  }
};

/**
 * getDashboard
 * @param fetchCredentials typeof fetch
 * @param year string
 * @param username string | null | undefined
 */
export const getDashboard = async (
  fetchCredentials: typeof fetch,
  year: string,
  username?: string | null
) => {
  /** URL dashboard */
  let URL_DASHBOARD: string = `${process.env.REACT_APP_API_URL_EL}${CONST.API.DASHBOARD}`;

  URL_DASHBOARD = URL_DASHBOARD.replace(/:year/, year.toString());

  /** Params  */
  let params = {};

  if (username) {
    params = {
      username
    };
  }

  let result = null;

  /** Result */
  try {
    result = await fetchCredentials(build(URL_DASHBOARD, params));
    throwErrorResponse(result);
    return await result.json();
  } catch (err) {
    throw err;
  }
};

/**
 * getActivityWordFile
 * @param fetchCredentials typeof fetch
 * @param activityType
 * @param activity
 */
export const getActivityWordFile = async (
  fetchCredentials: typeof fetch,
  activityType: string,
  activity: Activity
) => {
  /** URL activity word file */
  let URL_ACTIVITY_WORD_FILE: string = `${process.env.REACT_APP_API_URL_EL}${CONST.API.ACTIVITY_WORD_FILE}`;
  URL_ACTIVITY_WORD_FILE = URL_ACTIVITY_WORD_FILE.replace(
    /:activityType/,
    activityType
  );
  URL_ACTIVITY_WORD_FILE = URL_ACTIVITY_WORD_FILE.replace(
    /:activityId/,
    activity.id.toString()
  );

  let result = null;
  /** Result */
  try {
    result = await fetchCredentials(URL_ACTIVITY_WORD_FILE);

    const url = window.URL.createObjectURL(await result.blob());
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${activity.name}.docx`);
    document.body.appendChild(link);
    link.click();

    return true;
  } catch (err) {
    return false;
  }
};

/**
 * Post activitys valided questions
 * @param fetchCredentials
 * @param activityId
 * @param questionId
 * @param userAnswers
 */
export const postValidedQuestions = async (
  fetchCredentials: typeof fetch,
  activityId: number,
  questionId: number,
  userAnswers: Object
) => {
  /** URL Quiz validated */
  let URL_QUIZ_VALIDED: string = `${process.env.REACT_APP_API_URL_EL}${CONST.API.QUIZ_VALIDED_QUESTION}`;
  URL_QUIZ_VALIDED = URL_QUIZ_VALIDED.replace(
    /:activityId/,
    activityId.toString()
  );
  URL_QUIZ_VALIDED = URL_QUIZ_VALIDED.replace(
    /:questionId/,
    questionId.toString()
  );

  /** params */
  const params = {
    userAnswers
  };

  const result = await fetchCredentials(URL_QUIZ_VALIDED, {
    method: "post",
    body: JSON.stringify(params)
  });

  return await result.json();
};

/**
 * Reset quiz answers
 * @param fetchCredentials typeof fetch
 * @param activityId number
 */
export const resetAnswers = async (
  fetchCredentials: typeof fetch,
  activityId: number
) => {
  /** URL reset quiz */
  let URL_RESET_ANSWERS: string = `${process.env.REACT_APP_API_URL_EL}${CONST.API.QUIZ_RESET}`;
  URL_RESET_ANSWERS = URL_RESET_ANSWERS.replace(
    /:activityId/,
    activityId.toString()
  );

  const result = await fetchCredentials(URL_RESET_ANSWERS, { method: "post" });

  return await result.json();
};

/**
 * Get certificate PDF
 * @param fetchCredentials
 * @param from
 * @param to
 * @param user
 */
export const downloadCertificate = async (
  fetchCredentials: typeof fetch,
  from: Date,
  to: Date,
  user?: User | null
) => {
  /** Current user */
  user = user || store.getState().user;
  const fileUsername = `${user!.firstname}-${user!.lastname}`;

  /** URL certificate */
  const URL_CERTIFICATE: string = `${process.env.REACT_APP_API_URL_EL}${
    CONST.API.CERTIFICATE
  }?username=${user!.username}`;

  /** params */
  const fromDate = from.toISOString().split("T")[0];
  const toDate = to.toISOString().split("T")[0];
  const params = {
    from: fromDate,
    to: toDate
  };

  const result = await fetchCredentials(URL_CERTIFICATE, {
    method: "post",
    body: JSON.stringify(params)
  });

  const url = window.URL.createObjectURL(await result.blob());
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute(
    "download",
    `Attestation-RF-e-Learning-${moment(fromDate).format(
      "DD-MM-YYYY"
    )}-${moment(toDate).format("DD-MM-YYYY")}-${fileUsername}.pdf`
  );
  document.body.appendChild(link);
  link.click();
};
